import moment from 'moment';
import { monthNames } from '@/utils/datepicker';

const monthsWithEndings = {
  Январь: 'Января',
  Февраль: 'Февраля',
  Март: 'Марта',
  Апрель: 'Апреля',
  Май: 'Мая',
  Июнь: 'Июня',
  Июль: 'Июля',
  Август: 'Августа',
  Сентябрь: 'Сентября',
  Октябрь: 'Октября',
  Ноябрь: 'Ноября',
  Декабрь: 'Декабря',
};

// Вычисление первого дня месяца
export const calculateFirstDayOfMonth = (date) => {
  const firstDay = moment(date).startOf('month');
  return firstDay.day() === 0 ? 7 : firstDay.day();
};

// Получение номера недели для заданной даты
const getISOWeekNumber = (date) => {
  return moment(date).isoWeek();
};

export const calculateNumberOfWeeks = (date) => {
  const firstDay = moment(date).startOf('month');
  const lastDay = moment(date).endOf('month');

  const firstWeekNumber = firstDay.isoWeek();
  const lastWeekNumber = lastDay.isoWeek();

  if (firstWeekNumber > lastWeekNumber) {
    // Если месяц пересекает границу года
    const weeksInYear = moment(date).endOf('year').isoWeeksInYear();
    return weeksInYear - firstWeekNumber + 1 + lastWeekNumber;
  }

  return lastWeekNumber - firstWeekNumber + 1;
};

// Вычисление текущей недели месяца
export const calculateCurrentWeekOfMonth = (date) => {
  const firstDayOfMonth = moment(date).startOf('month');
  const currentWeekNumber = getISOWeekNumber(date);
  const firstWeekNumber = getISOWeekNumber(firstDayOfMonth);

  return currentWeekNumber - firstWeekNumber + 1;
};

// Получение количества дней в текущем месяце
export const calculateDaysInCurrentMonth = (date) => {
  return moment(date).daysInMonth();
};

// Добавление окончания к названию месяца
export const addMonthEnding = (date) => {
  const monthIndex = moment(date).month();
  const monthName = monthNames[monthIndex];
  return monthName;
};

// Получение названия месяца с окончанием
export const getMonthWithEnding = (monthName) => {
  return monthsWithEndings[monthName] || monthName;
};

// Получение диапазона дат для определенной недели
export const getWeekDateRange = (date, weekIndex) => {
  const totalDays = calculateDaysInCurrentMonth(date);
  const monthIndex = moment(date).month();
  const monthName = monthsWithEndings[monthNames[monthIndex]];

  const firstDayOfMonth = moment(date).startOf('month');
  const firstDayWeekday = firstDayOfMonth.day();

  const weekStartDay = weekIndex * 7 + 1;
  const adjustedStartDay = weekStartDay - firstDayWeekday;
  const startDay = Math.max(1, adjustedStartDay);
  const endDay = Math.min(startDay + 6, totalDays);

  if (endDay < 1 || startDay > totalDays) {
    return null;
  }

  if (startDay === endDay) {
    return `${startDay}, ${monthName}`;
  } else {
    return `${startDay} - ${endDay}, ${monthName}`;
  }
};
